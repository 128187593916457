<template>
  <div class="Terms flex justify-center">
    <v-container class="pa-12">
      <h2 class="mt-8 pt-12 pb-12 display-1 font-weight-medium text-center">
        Terms and conditions
      </h2>

      Want the <span class="font-weight-bold">best</span> looking House on the
      street? <span class="font-weight-bold">Go Clean's team</span> can help and
      design a maintenance schedule to suit you and your property, <br />
      to keep your house clean and looking new again. Whether its regular
      cleaning and maintenance, or a one-off clean up, the team at
      <span>Go Clean</span> can assist you. <br />
      <br />
      Just call:<a class="font-weight-black" href="tel:+64-027-440-6794">
        027-440-6794</a
      >
      or Request a Quote Online. <br />
      <br />
      Get your weekends back, and let the Go Clean team take care of the dirty
      work. Let our fully trained team get your cleaning done professionally,
      with the right equipment & fast. Give yourself more time to relax and
      enjoy your home again, rather than working in it. <br />
      The Go Clean team offer a comprehensive range of cleaning services
      including: <br />
      &bull; Window cleaning <br />
      &bull; Gutter cleaning <br />
      &bull; Carpet cleaning <br />
      &bull; Water Blasting: High/Low Pressure house washing <br />
      <br />
      Whether your property is in between tenants or about to be put on the
      market – a well maintained home will always add to its value.
      <br />
      <br />
      Just call:<a class="font-weight-black" href="tel:+64-027-440-6794">
        027-440-6794</a
      >
      or Request a Quote Online.
      <br />
      <div class="pa-12 float-right">
        <dialog1 title="Contact Us" />
      </div>
    </v-container>
  </div>
</template>

<script>
import dialog1 from "@/components/dialog1.vue";
export default {
  name: "Terms",
  components: {
    dialog1,
  },
  metaInfo: {
    title: "GoClean - Terms & Conditions",
    meta: [
      {
        name: "description",
        content: "GoClean - Ensuring Quality on all levels.",
      },
    ],
  },
};
</script>


